import { render, staticRenderFns } from "./PlayerAdder.vue?vue&type=template&id=86e32564&"
import script from "./PlayerAdder.vue?vue&type=script&lang=js&"
export * from "./PlayerAdder.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCard,VIcon,VImg,VMenu,VSheet,VTextField})
